<template>
  <div :hidden="child == null" class="hide-for-small-only">

    <div v-if="htmlTitle">
      <span v-html="childRecordTitle"></span>&nbsp;&nbsp;
      <md-button class="md-icon-button" style="margin-top: -10px;" @click="onToggleChildAbsence()">
        <md-icon v-if="isHereToday(child)" style="color: green;">check</md-icon>
        <md-icon v-else style="color: red;">close</md-icon>
        <md-tooltip>Anwesenheit für <b>{{ today | moment("DD.MM.YYYY") }}</b> ändern</md-tooltip>
      </md-button>
      <md-tooltip md-direction="top">
        <span v-if="isHereToday(child)">{{this.labels.absenceStatus[child.absenceStatusToday]}}</span>
        <span v-else class="ai-alert-text">{{this.labels.absenceStatus[child.absenceStatusToday]}}</span>
      </md-tooltip>
      <br><br>
      <span v-html="childRecordSubTitle"></span>
    </div>

    <div v-else-if="htmlBody">
      <router-link :to="'/Kinder/'+ child.id">
        <p v-html="childRecordBody"></p>
        <md-tooltip md-delay="500" style="font-size: medium;">Daten öffnen für <b>{{child.masterdata.fullName}}</b></md-tooltip>
      </router-link>
    </div>

    <div v-else-if="toolTipOnly" v-html="toolTipText"><div hidden>{{updateToolTip()}}</div></div>

    <fab v-else position="top-right" ripple-color="dark" bg-color="#269FBA" icon-size="large"
         toggle-when-away="" start-opened="" z-index="4"
         main-icon="face" :main-tooltip="toolTipText" :actions="fabActions">
    </fab>

  </div>
</template>

<script>
  import FAB from 'vue-fab';
  import jQuery from 'jquery';
  import Vue from 'vue';
  import ChildService from "@/services/ChildService";
  import HttpErrorHandler from "@/services/HttpErrorHandler";

  export default {
    name: 'ChildBriefRecord',

    components: {
      fab: FAB,
    },

    props: {
      child: {
        required: true
      },
      groups: {
        required: true
      },
      htmlTitle: {
        required: false
      },
      htmlBody: {
        required: false
      },
      toolTipOnly: {
        required: false
      },
    },

    watch: {
      child() {
        this.updateToolTip();
        this.moveFab();
      },
      groups() {
        this.updateToolTip();
        this.moveFab();
      },
    },

    mounted() {
      this.moveFab();
    },

    data() {
      return {
        showBadge: false,
        fabActions: [],
        toolTipText: '',
        toolTipReSized: true,
        toolTipStyles: '',

        today: Vue.moment(),
      }
    },

    methods: {

      onToggleChildAbsence() {
        let date = Vue.moment().format('YYYY-MM-DD');
        ChildService.toggleChildAbsence(this.child.id, date)
          .then((response) => {
            this.child.absenceStatusToday = response.data.absenceStatusToday;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Anwesenheit von Kind <b>' + this.child.masterdata.fullName + '</b>');
          })
      },

      isHereToday(child) {
        return child.absenceStatusToday.toString().startsWith('HERE');
      },

      updateToolTip() {
        if (this.child && this.groups) {
          let toolTipText = '<p id="childBriefRecordId" style="font-family: \'Avenir\', Helvetica, Arial, sans-serif;font-weight: 300;">Steckbrief von:<br><br>';
          if (this.htmlTitle) {
            toolTipText = '<p id="childBriefRecordId" style="font-weight: 300;">';
          }

          toolTipText += this.childRecordTitle + '<br>' + this.childRecordSubTitle;
          toolTipText += this.childRecordBody;
          toolTipText += '</p>';

          this.toolTipText = toolTipText;
        }
      },

      moveFab() {
        let topRightWrapper = jQuery('#top-right-wrapper');
        topRightWrapper.attr('style', '');
        topRightWrapper.addClass('child-brief-record-position');

        jQuery("div[x-placement=left]").children('.tooltip-inner').attr('style', 'width: 25rem; position: relative; left: -16rem;');
        jQuery('#childBriefRecordId').parent().attr('style', 'padding: 0');

        if (this.toolTipText.length > 0 && !this.toolTipReSized) {
          setTimeout(() => {
            jQuery('.child-brief-record-position').children('.fab-main.pointer').click();

            setTimeout(() => {
              let toolTipFab = jQuery('.tooltip.fab-tooltip');
              this.toolTipStyles = toolTipFab.attr('style');
              toolTipFab.attr('style', 'z-index: 0');

              jQuery("div[x-placement=left]").children('.tooltip-inner').attr('style', 'width: 25rem; position: relative; left: -16rem;');
              setTimeout(() => {
                jQuery('.child-brief-record-position').children('.fab-main.pointer').click();
                toolTipFab.attr('style', this.toolTipStyles);
                this.toolTipReSized = true;
              }, 1500);
            }, 100);
          }, 200);
        }

      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      childRecordTitle() {
        let title = '';

        if (this.child) {
          if (this.child.masterdata.sex === 'MALE') {
            title += '<span class="MALE">&#9794;';
          } else if (this.child.masterdata.sex === 'FEMALE') {
            title += '<span class="FEMALE">&#9792;';
          } else if (this.child.masterdata.sex === 'DIVERSE') {
            title += '<span class="DIVERSE">&#9954;';
          }
          title += '</span>&nbsp;';

          title += '<span style="font-size: x-large;">' + this.child.masterdata.fullName + '</span>';
        }

        return title;
      },

      childRecordSubTitle() {
        let subTitle = '';

        if (this.child) {
          subTitle += '<span style="color: lightseagreen; font-size: 1.2rem; font-weight: 500;">' + this.child.ageTextual + '</span>&nbsp;&nbsp;';
          subTitle += '<b style="font-size: 1.5rem;" class="' + this.child.divisiontype + '">' + this.labels.divisiontype[this.child.divisiontype] + '</b>&nbsp;<br>';
        }

        return subTitle;
      },

      childRecordBody() {
        let body = '';

        if (this.child && this.groups) {
          if (this.child.sibling && this.child.siblingNamesString) {
            body += 'Geschwister:&nbsp;&nbsp';
            body += '<b style="color: greenyellow; font-size: 1.1rem; font-weight: 400;">' + this.child.siblingNamesString + '</b><br>';
          }

          if (this.child.groupReferences && !this.child.archived) {
            body += 'Gruppe';
            if (this.child.groupReferences.length > 1) {
              body += 'n';
            }
            body += ':&nbsp;&nbsp';
            if (this.child.groupReferences.length === 0) {
              body += '<b style="color: crimson; font-size: 1.1rem; font-weight: 400;">Nicht zugeordnet</b>';
            }
            for (let groupReference of this.child.groupReferences) {
              for (let group of this.groups) {
                if (group.id.includes(groupReference)) {
                  body += '<span><b style="color: chocolate; font-size: 1.1rem; font-weight: 400;">' + group.name + '&nbsp;&nbsp;</b></span>';
                }
              }
            }
            body += '<br>';
          }

          if (this.child.kibigName) {
            body += '<span>KiBiG Name: <b style="color: crimson; font-size: 1.0rem; font-weight: 300;">' + this.child.kibigName + '</b></span>&nbsp;&nbsp;&nbsp;';
          }
          if (this.child.kibigId) {
            body += '<span>KiBiG Id: <b style="color: crimson; font-size: 1.0rem; font-weight: 300;">' + this.child.kibigId + '</b></span><br>';
          }

          body += '<br>';

          body += '<span>Eintritt: ';
          if (this.child.entryDate) {
            body += '<b style="color: forestgreen; font-size: 1.0rem; font-weight: 300;">' + Vue.moment(this.child.entryDate).format('DD.MM.YYYY') + '</b>';
          } else {
            body += '<b style="color: red; font-size: 1.0rem; font-weight: 300;">Keine Angabe</b>';
          }
          body += '</span>&nbsp;&nbsp;&nbsp;';

          body += '<span>Austritt: ';
          if (this.child.exitDate) {
            body += '<b style="color: forestgreen; font-size: 1.0rem; font-weight: 300;">' + Vue.moment(this.child.exitDate).format('DD.MM.YYYY') + '</b>';
          } else {
            body += '<b style="color: red; font-size: 1.0rem; font-weight: 300;">Keine Angabe</b>';
          }
          body += '</span><br>';

          body += '<span>Förderkommune: <b style="color: coral; font-size: 1.0rem; font-weight: 300;">' + this.child.promotionCommunityName + '</b><br>';
        }

        return body;
      },
    }
  }
</script>

<style type="text/css" lang="scss">

  .md-tooltip {
    font-size: medium;
    white-space: normal;
    max-width: 300px;
    height: auto;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .child-brief-record-position {
    transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out, top 0.25s ease-in-out;
    opacity: 0.9;
    top: 13rem;
    right: 1.7rem;
    z-index: 6;
    position: fixed;
  }

  .material-icons {
    color: #424242;
  }

  .fab-tooltip.tooltip {
    display: block !important;
    padding: 10px;
    background: transparent;
    z-index: 10000;
  }

  .fab-tooltip.tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .15s;
    transition: opacity 1s;
  }

  .fab-tooltip.tooltip .tooltip-inner {
    max-height: 5rem;
  }

  .fab-tooltip.tooltip .tooltip-inner p {
    background: #333333;
    color: white;
    border-radius: 0;
    padding: 10px 20px;
    border-width: 0;
    font-size: 1rem;
    width: 25rem;
    position: relative;
    left: -16rem;
    top: -10rem;
  }

  .KRIPPE {
    color: lightpink;
    background: none;
  }

  .KINDERGARTEN {
    color: lightskyblue;
    background: none;
  }

  .HORT {
    color: lightgreen;
    background: none;
  }

  .UNDEFINED {
    color: red;
    background: none;
  }
</style>